import React, { useEffect, useState, Suspense } from "react";
import { Tab, Tabs } from "react-bootstrap";
import { BrowserView, MobileView } from "react-device-detect";
import { connect } from "react-redux";
import useWorkstationGridData from "../hooks/useWorkstationGridData";
import {
  allLongTermReservationAction,
  allPermanentReservationAction,
  allShortTermReservationAction,
  setLongTermReservationRequestsAction,
} from "../reduxActions/reservationActions";
import {
  getAllAvailableWorkstationGridData,
} from "../reduxActions/workstationActions";
import { setPermanentReservationRequests } from "../reduxActions/permanentReservationActions";
import AdminService from "../reduxServices/AdminService";
import ReservationService from "../reduxServices/ReservationService";
import ServiceRequestorClient from "../reduxServices/ServiceRequestorClient";
import WorkstationProblemReportService from "../reduxServices/WorkstationProblemReportService";
import WebsiteProblemReportService from "../reduxServices/WebsiteProblemReportService";
import WorkstationService from "../reduxServices/WorkstationService";
import { store } from "../store";
import "../styles/pages/App.scss";
import { filterReservationsByType } from "../utilities/ReservationUtilities";
import LoadSpinner from "./LoadSpinner";
import ErrorBoundary from "./ErrorBoundary";
const AllAvailableWorkstationForm =  React.lazy(() => import('./AllAvailableWorkstationForm'));
const BrowserConsolidatedView = React.lazy(() => import('./BrowserConsolidatedView'));
const AdminAllAvailableWorkstationForm = React.lazy(() => import('./AdminAllAvailableWorkstationForm'));
const AddAdminForm = React.lazy(() => import('./AddAdminForm')) ;
const AdminRequestTable = React.lazy(() => import('./AdminRequestTable'));
const LongTermWorkstationRequestTable = React.lazy(() => import('./LongTermWorkstationRequestTable'));
const PermanentReservationRequest = React.lazy(() => import('./PermanentReservationRequest')) ;
const MobileAdminRequestTable = React.lazy(() => import('./MobileAdminRequestTable'));
const MobileLTWorkstationRequestTable = React.lazy(() => import('./MobileLTWorkstationRequestTable')) ;
const WorkstationGrid = React.lazy(() => import('./WorkstationGrid.js'));
const AdminWebsiteReportViewer = React.lazy(() => import('./AdminReportsViewTab/AdminWebsiteReportViewer'));
const AdminWorkstationReportViewer = React.lazy(() => import('./AdminReportsViewTab/AdminWorkstationReportViewer'));
const MobileConsolidatedView = React.lazy(() => import('./MobileConsolidatedView'));
// const ViewReportsTab = React.lazy(() => import('./AdminReportsViewTab/ViewReportsTab'));

/**
 * Main content of App. Contains all tabs for users and admins
 * Preloads data used accross the app
 */
const MainContent = (props) => {
  const adminObject = props.adminObject;

  const [refreshMain, setRefreshMain] = useState(true);
  const [reservationRequest, setReservationRequest] = useState([]);
  const currentUserEmail = ServiceRequestorClient.getUserInfo().adminEmail;

  const fetchReservations = async () => {
    ReservationService.getAllReservations().then((response) => {
      props.setAllShortTermReservations(
        filterReservationsByType(response.data, "short")
      );

      props.setAllLongTermReservations(
        filterReservationsByType(response.data, "long")
      );

      props.setAllPermanentReservations(
        filterReservationsByType(response.data, "permanent")
      );
    });

  };

  useEffect(() => {
    fetchReservations();
    AdminService.getAdminByEmail(currentUserEmail);
    WorkstationService.getAllWorkstations().then((response) => {
      store.dispatch(
        getAllAvailableWorkstationGridData(useWorkstationGridData(response))
      );
    });
  }, []);

  useEffect(() => {
    setRefreshMain(true);
  }, [refreshMain]);

  const handleSelect = (eventKey) => {
    switch (eventKey) {
      case "problemReportView":
        WebsiteProblemReportService.getAllWebsiteProblemReports();
        WorkstationProblemReportService.getAllWorkstationProblemReports();

        return;
      case "home":
        fetchReservations();
        return;
      case "myResView":
        ReservationService.getAllReservations(currentUserEmail);
        WorkstationService.getWorkstations(null, true);
        return;
      case "allResView":
        WorkstationService.getWorkstations(null, true);
        ReservationService.getAllUsers();
        return;
      case "adminView":
        // AdminService.getAdminRequests();
        AdminService.getAdminRequests()
        ReservationService.getAllReservationRequests().then(response => {
          props.setLongTermReservationRequests(filterReservationsByType(response.data, "long"));
        });
        ReservationService.getAllReservationRequests().then((response) => {
          props.setPermanentReservationRequests(filterReservationsByType(response.data, "permanent"));
        });
        return;
      case "editWkstnView":
        return;
      default:
        return;
    }
  };


  return (
    <>
      <div className="App">
        <BrowserView>
          <Tabs
            defaultActiveKey="home"
            className="navtabs"
            onSelect={handleSelect}
          >
            <Tab
              className="navtabs-tab"
              eventKey="home"
              title="Create Reservations"
            >
              <ErrorBoundary>
                <Suspense fallback={<LoadSpinner />}>
                  <AllAvailableWorkstationForm
                    gridData={props.getAllAvailableWorkstationGridData}
                    adminMode={true}
                    setReservationRequest={setReservationRequest}
                  />
                </Suspense>
              </ErrorBoundary>
            </Tab>
            <Tab eventKey="myResView" title="My Reservations">
              <ErrorBoundary>
                <Suspense fallback={<LoadSpinner />}>
                  <BrowserConsolidatedView adminStatus={true} />
                </Suspense>
              </ErrorBoundary>
            </Tab>
            <Tab
              className="navtabs-tab"
              eventKey="allResView"
              title="All Reservations"
            >
              <ErrorBoundary>
                <Suspense fallback={<LoadSpinner />}>
                  {adminObject.length >= 1 ? (
                    <AdminAllAvailableWorkstationForm />
                  ) : (
                    <></>
                  )}
                  <BrowserConsolidatedView
                    type={"all"}
                    adminStatus={adminObject.length >= 1 ? true : false}
                    AllReservationsTab={true}
                  />
                </Suspense>
              </ErrorBoundary>
            </Tab>

            {adminObject.length >= 1 ? (
              <Tab
                className="navtabs-tab"
                eventKey="adminView"
                title="Admin Controls"
              >
                <>
                  <ErrorBoundary>
                    <Suspense fallback={<LoadSpinner />}>
                      <AddAdminForm />
                    </Suspense>
                  </ErrorBoundary>
                  <BrowserView>
                    <ErrorBoundary>
                      <Suspense fallback={<LoadSpinner />}>
                        <AdminRequestTable />
                        <LongTermWorkstationRequestTable />
                        <PermanentReservationRequest
                          reservationRequest={reservationRequest}
                          setReservationRequest={setReservationRequest}
                        />
                      </Suspense>
                    </ErrorBoundary>
                  </BrowserView>
                  <MobileView>
                    <ErrorBoundary>
                      <Suspense fallback={<LoadSpinner />}>
                        <MobileAdminRequestTable />
                        <MobileLTWorkstationRequestTable />
                      </Suspense>
                    </ErrorBoundary>
                  </MobileView>
                </>
              </Tab>
            ) : (
              <></>
            )}

            {adminObject.length >= 1 ? (
              <Tab
                className="navtabs-tab"
                eventKey="editWkstnView"
                title="Edit Workstations"
              >
                <ErrorBoundary>
                  <Suspense fallback={<LoadSpinner />}>
                    {refreshMain ? (
                      <>
                        <WorkstationGrid
                          adminMode={true}
                          gridData={props.getAllAvailableWorkstationGridData}
                          scale={false}
                          setRefreshMain={setRefreshMain}
                          showLegend={false}
                        />
                      </>
                    ) : null}
                  </Suspense>
                </ErrorBoundary>
              </Tab>
            ) : (
              <></>
            )}

            {adminObject.length >= 1 ? (
              <Tab
                className="navtabs-tab"
                eventKey="problemReportView"
                title="View Reports"
              >
                <ErrorBoundary>
                  <Suspense fallback={<LoadSpinner />}>
                    <AdminWorkstationReportViewer />
                    <AdminWebsiteReportViewer
                      websiteProblemReportReducer={
                        props.websiteProblemReportReducer
                      }
                    />
                  </Suspense>
                </ErrorBoundary>
              </Tab>
            ) : (
              <></>
            )}
            
          </Tabs>
        </BrowserView>

        <MobileView>
          {props.activeMobileComponent === "AllAvailableWorkstationForm" ? (
            <ErrorBoundary>
              <Suspense fallback={<LoadSpinner />}>
                <AllAvailableWorkstationForm
                  gridData={props.getAllAvailableWorkstationGridData}
                  adminMode={true}
                  setReservationRequest={setReservationRequest}
                />
              </Suspense>
            </ErrorBoundary>
          ) : null}

          {props.activeMobileComponent === "MobileConsolidatedView" ? (
            <ErrorBoundary>
              <Suspense fallback={<LoadSpinner />}>
                <MobileConsolidatedView adminStatus={true} />
              </Suspense>
            </ErrorBoundary>
          ) : null}


          {props.activeMobileComponent === "AddAdminForm" ? (
            <>
              <ErrorBoundary>
                <Suspense fallback={<LoadSpinner />}>
                  <AddAdminForm />
                  <MobileAdminRequestTable />
                  <MobileLTWorkstationRequestTable />
                  </Suspense>
              </ErrorBoundary>
            </>
          ) : null}

          {props.activeMobileComponent === "UserAllReservationsView" ? (
              <ErrorBoundary>
                <Suspense fallback={<LoadSpinner />}>
                  <MobileConsolidatedView
                    type={"all"}
                    adminStatus={adminObject.length >= 1}
                    AllReservationsTab={true}
                  />
                </Suspense>
              </ErrorBoundary>
          ) : null}

          {props.activeMobileComponent ===
          "AdminAllAvailableWorkstationForm" ? (
              <>
                <ErrorBoundary>
                  <Suspense fallback={<LoadSpinner />}>
                    <AdminAllAvailableWorkstationForm />
                    <MobileConsolidatedView
                      type={"all"}
                      adminStatus={adminObject.length >= 1}
                      AllReservationsTab={true}
                    />
                  </Suspense>
                </ErrorBoundary>
              </>
          ) : null}

          {props.activeMobileComponent === "AdminReportViewer" ? (
            <>
              {adminObject.length >= 1 ? (
                <>
                  <ErrorBoundary>
                    <Suspense fallback={<LoadSpinner />}>
                      <AdminWorkstationReportViewer />
                      <AdminWebsiteReportViewer
                        websiteProblemReportReducer={
                          props.websiteProblemReportReducer
                        }
                      />
                    </Suspense>
                  </ErrorBoundary>
                </>
              ) : (
                <></>
              )}
            </>
          ) : null}
        </MobileView>
      </div>
      <footer className="footer-container">
        © Concord 2022. All rights reserved.
      </footer>
    </>
  );
};

window.onbeforeunload = function () {
  window.scrollTo(0, 0);
};

const mapStateToProps = (state) => {
  return {
    adminObject: state.adminReducer,
    getAllAvailableWorkstationGridData:
      state.getAllAvailableWorkstationGridDataReducer,
    websiteProblemReportReducer: state.websiteProblemReportReducer,
    allWorkstationStates: state.allWorkstationStates,

    // New reducers
    allReservations: state.allReservationReducer,
    allLongTermReservations: state.allLongTermReservationReducer,
    allPermanentReservations: state.allPermanentReservationReducer,
    allUserReservations: state.myReservationReducer,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {

    // New Dispatches
    setAllShortTermReservations: (response) =>
      dispatch(allShortTermReservationAction(response)),
    setAllLongTermReservations: (response) =>
      dispatch(allLongTermReservationAction(response)),
    setAllPermanentReservations: (response) =>
      dispatch(allPermanentReservationAction(response)),
    setLongTermReservationRequests: (response) => 
      dispatch(setLongTermReservationRequestsAction(response)),
    setPermanentReservationRequests: (response) =>
      dispatch(setPermanentReservationRequests(response)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MainContent);

function allWebsiteReports(payload) {
    return {
        type: 'SET_ALL_REPORTS',
        payload: payload
    }
}

function addWebsiteReport(payload) {
    return {
        type: 'ADD_WEBSITE_REPORT',
        payload: payload
    }
}

function updateWebsiteReportStatus(payload) {
    return {
        type: 'UPDATE_WEBSITE_REPORT_STATUS',
        payload: payload
    }
}

export {
    allWebsiteReports,
    addWebsiteReport,
    updateWebsiteReportStatus
};
function adminAction(payload) {
  return {
    type: 'SET_ADMIN',
    payload: payload,
  };
}

function userListAction(payload) {
  return {
    type: 'SET_USER_LIST',
    payload: payload,
  };
}

function LTuserListAction(payload) {
  return {
    type: 'SET_LTUSER_LIST',
    payload: payload,
  };
}

export { adminAction, userListAction, LTuserListAction };

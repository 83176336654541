import React, { useEffect } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import * as logUtils from '../utilities/LoggerUtilties';
import { NavbarPackage } from './NavbarPackage';


/**
 * 
 * Renders the navbar component with a sign-in button if a user is not authenticated
 * @component
 */
export const PageLayout = (props) => {
  var log = logUtils.getLogger();

  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    log.debug('Setting page layout');
  }, []);


  return (
    <>
      {isAuthenticated ? <NavbarPackage setActiveMobileComponent={props.setActiveMobileComponent}/> :  <></>}
      {props.children}
    </>
  );
};

import { allWebsiteReports, addWebsiteReport } from '../reduxActions/websiteReportActions';

import ServiceRequestorClient from './ServiceRequestorClient';

/** Handles website report operations */
export default new (class WebsiteProblemReportService {

    /** Creates a webiste report */
    async createWebsiteReport(websiteReport) {
        return await ServiceRequestorClient.makeRequestToService(
            "POST", "/reports/website/", websiteReport, addWebsiteReport,
            () => {
                alert("Problem creating website report")
            }
        )
    }

    /** Fetches all webiste reports */
    async getAllWebsiteProblemReports() {
        return await ServiceRequestorClient.makeRequestToService(
            "GET", "/reports/website/", null, allWebsiteReports
        )
    }

        /** Fetches all webiste reports */
        async getSingleWebsiteProblemReport(reportId) {
            return await ServiceRequestorClient.makeRequestToService(
                "GET", `/reports/website/${reportId}`, null, allWebsiteReports
            )
        }

    async editSingleWebsiteProblemReportStatusById(reportId, websiteReport) {
        return await ServiceRequestorClient.makeRequestToService(
            "PATCH", `/reports/website/${reportId}`, websiteReport, null
        )
    }
})();

const initialState = {
  showModal: false,
};

export default function reservationFormReducer(state = initialState, action) {
  switch (action.type) {
    case "TOGGLE_MODAL":
      return Object.assign({}, state, { showModal: action.payload });
    default:
      return state;
  }
}

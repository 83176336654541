import { dateToString } from "../utilities/DateUtilities";

export function filterReservationsByType(reservations, type) {
  return reservations.filter(reservation => reservation.reservationType === type)
}

//custom extract to help display res info to user
export function extractInfoFromReservation(reservation) {
  if (typeof reservation === 'undefined') {
    return ""
  }
  let reservationInfo = ""
  reservationInfo = reservationInfo + "Workstation: " + reservation.workstationId + " - " 
  reservationInfo = reservationInfo + "Day Reserved: " + reservation.startDate
  return reservationInfo
}

export function extractInfoFromLongTermReservation(reservation) {
  if (typeof reservation === 'undefined') {
    return ""
  }
  let reservationInfo = ""
  reservationInfo = reservationInfo + "Workstation: " + reservation["workstationId"] + " - "
  reservationInfo = reservationInfo + "Reserved from " + dateToString(reservation["startDate"])+" to "+dateToString(reservation["endDate"]);
  return reservationInfo
}

export function extractDateFromReservation(reservation) {
  if (typeof reservation === 'undefined') {
    return ""
  }
  let reservationInfo = ""
  reservationInfo = reservationInfo + dateToString(reservation["startDate"]).split(/-(.+)/)[1]
  return reservationInfo
}

export function extractStartDateFromLongTermReservation(reservation) {
  if (typeof reservation === 'undefined') {
    return ""
  }
  let reservationInfo = ""
  reservationInfo = reservationInfo + dateToString(reservation["startDate"]).split(/-(.+)/)[1]
  return reservationInfo
}

export function extractEndDateFromLongTermReservation(reservation) {
  if (typeof reservation === 'undefined') {
    return ""
  }
  let reservationInfo = ""
  reservationInfo = reservationInfo + dateToString(reservation["endDate"]).split(/-(.+)/)[1]
  return reservationInfo
}

export function extractLimitedInfoFromReservation(reservation) {
  if (typeof reservation === 'undefined') {
    return ""
  }
  let reservationInfo = ""
  reservationInfo = reservationInfo + "Workstation " + reservation["workstationId"] + " - "
  reservationInfo = reservationInfo + "Day Reserved: " + dateToString(reservation["startDate"])
  return reservationInfo
}

export function calculateReservationBlock(time, duration) {
   if ((time === 0 || time === 1) && duration === 8) {
    return "AM";
  }
  if ((time === 0 || time === 1) && duration === 16) {
    return "Full Day";
  }
  if (time === 8 || time === 9) {
    return "PM";
  }
}

export function descriptionRender(description) {
  if (description === null) {
    return "";
  } else {
      return `${description}`
  }
};
import * as logUtils from '../utilities/LoggerUtilties';

export default function adminReducer(state = [], action) {
  const log = logUtils.getLogger();
  switch (action.type) {
    case 'SET_ADMIN':
      log.debug('refreshing admin reducer');
      return action.payload;
    default:
      return state;
  }
}

function userListReducer(state = [], action) {
  const log = logUtils.getLogger();
  switch (action.type) {
    case 'SET_USER_LIST':
      log.debug('refreshing user list reducer');
      return action.payload;
    default:
      return state;
  }
}

function LTuserListReducer(state = [], action) {
  const log = logUtils.getLogger();
  switch (action.type) {
    case 'SET_LTUSER_LIST':
      log.debug('refreshing LT user list reducer');
      return action.payload;
    default:
      return state;
  }
}

export { adminReducer, userListReducer, LTuserListReducer};

import * as logUtils from '../utilities/LoggerUtilties';

function websiteProblemReportReducer(state = [], action) {
    const log = logUtils.getLogger();

    switch (action.type) {
        case 'SET_ALL_REPORTS':
            state = action.payload
            log.debug('Setting all website problem reports: ');
            return state

        case 'ADD_WEBSITE_REPORT':
            log.debug('Adding a website problem report to state: ');
            return [...state, action.payload]
        
        case 'UPDATE_WEBSITE_REPORT_STATUS':
            log.debug('Updating website report status to ');
            return state.map(row => {
                if (row.reportId === action.payload.reportId) {
                  return {
                    ...row,
                    ...action.payload,
                  };
                } else {
                  return row;
                }
              });

        default:
            return state;
    }
}

export {
    websiteProblemReportReducer
};
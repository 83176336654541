import React, { useState } from 'react';
import { Image } from 'react-bootstrap';
import * as logUtils from '../utilities/LoggerUtilties';
import HamburgerMenu from './HamburgerMenu';
import ConfirmAdminRequestModal from './ConfirmAdminRequestModal';
import "../styles/pages/NavbarPackage.scss";
import CombinedReportModal from './CombinedReportModal';


export const NavbarPackage = (props) => {
  const [requestMode, setRequestMode] = useState(false);
  const [showCombinedReportModal, setShowCombinedReportModal] = useState(false)
  var log = logUtils.getLogger();

  function handleLogout(instance) {
    log.debug('Attempting to logout');
    instance.logoutRedirect().catch((e) => {
      log.error('logout attempt failed due to :' + e);
    });
  }

  async function requestAdmin() {
    setRequestMode(true);
  }

  return (<div className="logo-navbar">
    <div className="logo-div">
      <Image
        src="dibs_Logo-Color.png"
        alt="Dibs Logo"
        rounded
        className="logo"
      />
    </div>
    <HamburgerMenu
      handleLogout={handleLogout}
      requestAdmin={requestAdmin}
      setShowCombinedReportModal={setShowCombinedReportModal}
      setActiveMobileComponent={props.setActiveMobileComponent}
    />
    <ConfirmAdminRequestModal
      requestMode={requestMode}
      setRequestMode={setRequestMode}
    />
    <CombinedReportModal
      showCombinedReportModal={showCombinedReportModal}
      setShowCombinedReportModal={setShowCombinedReportModal}
    />
  </div>

  )
}
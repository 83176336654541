import * as logUtils from '../utilities/LoggerUtilties';

const log = logUtils.getLogger();

export function AllAvailableWorkstationFormReducer(state = [], action) {
  switch (action.type) {
    case 'SET_AVAILABLE_WORKSTATION':
      return Object.assign({}, state, { showModal: action.payload });
    case 'UNSET_AVAILABLE_WORKSTATION':
      return [];
    default:
      return state;
  }
}


export function allAvailableWorkstationReducer(state = [], action) {
  const log = logUtils.getLogger();

  switch (action.type) {
    case 'SET_ALL_AVAILABLE_WORKSTATION':
      log.debug('Setting allAvailableWorkstationReducer...');
      return action.payload;
    case 'CLEAR_ALL_AVAILABLE_WORKSTATION':
      log.debug('Clearing allAvailableWorkstationReducer...');
      return [];
    default:
      return state;
  }
}

export function workstationListReducer(state = [], action) {
  const log = logUtils.getLogger();

  switch (action.type) {
    case 'SET_WORKSTATION_LIST':
      log.debug('Setting workstationListReducer...');
      return action.payload;
    case 'CLEAR_WORKSTATION_LIST':
      log.debug('Clearing workstationListReducer...');
      return [];
    default:
      return state;
  }
}

export function allAvailableReservationReducer(state = [], action) {
  const log = logUtils.getLogger();

  switch (action.type) {
    case 'SET_ALL_AVAILABLE_RESERVATION':
      log.debug('Setting allAvailableReservationReducer...');
      return action.payload;
    case 'CLEAR_ALL_AVAILABLE_RESERVATION':
      log.debug('Clearing allAvailableReservationReducer...');
      return [];
    default:
      return state;
  }
}

export function getAllAvailableWorkstationGridDataReducer(state = [], action) {
  const log = logUtils.getLogger();

  switch (action.type) {
    case 'SET_ALL_AVAILABLE_WORKSTATION_GRID_DATA':
      log.debug('Getting all available workstation grid data...');
      return action.payload;

    case 'UPDATE_MULTIPLE_WORKSTATION_POSITIONS':
      log.debug('Updating multiple workstation positions...');
      // const lastUpdatedInformation = action.payload.slice(-1)

      // // return {
      // //   ...state,
      // //   [lastUpdatedInformation[0][0]]: lastUpdatedInformation[0][1]
      // // };
      return action.payload;

    default:
      return state;
  }
}

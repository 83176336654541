function workstationProblemReportAction(payload) {
  return {
    type: 'SET_WORKSTATION_PROBLEM_REPORTS',
    payload: payload,
  };
}

function workstationProblemReportAddAction(payload) {
  return {
    type: 'ADD_WORKSTATION_REPORT',
    payload: payload,
  }
}

export {
  workstationProblemReportAction,
  workstationProblemReportAddAction
};
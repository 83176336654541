import React, { useState, forwardRef } from "react";
import { Dropdown, FormControl, ButtonGroup } from "react-bootstrap";

/** Allows users to first choose a pod, then choose a workstation in that pod
 * @component
 * @param {*} props
 * @returns Pair of dropdowns for pod and workstation
 */
const CustomToggle = forwardRef(({ children, onClick }, ref) => (
  <button
    ref={ref}
    onClick={e => {
      e.preventDefault();
      onClick(e);
    }}
    className="primary-btn wkstn-btn search-dropdown"
  >
    {children}
    <span className="search-dropdown__caretdown">&#9660;</span>
  </button>
));

export default function WorkstationDropDown(props) {
  const [selectedWorkstation, setSelectedWorkstation] = useState('Search Workstations')
  const workstationList = props.workstationList;

  const CustomMenu = forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      const [value, setValue] = useState("");
      const newStyles = { ...style, height: props.isSearchable && '300px'}
      return (
        <button
          type="button"
          ref={ref}
          style={newStyles}
          className={className}
          aria-labelledby={labeledBy}
        >
          <FormControl
            // autoFocus
            id="menu-form-control"
            className="mx-3 my-2 w-auto"
            placeholder="search"
            onChange={e => setValue(e.target.value)}
            value={value}
          />
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              child =>
                !value || child.props.children.toLowerCase().startsWith(value.toLowerCase())
            )}
          </ul>
          </button>
      );
    }
  );

  const handleSelectWorkstationId = (event) => {
    props.workstationClick(event);
    setSelectedWorkstation(event)
  };  

  return (
    <div>
      {typeof workstationList !== 'undefined' &&
        workstationList.length > 0 && (
          <div id="workstation-dropdown" className="center" >
            <Dropdown as={ButtonGroup} aria-controls='Workstation selector' aria-label="Workstation selector">
              <Dropdown.Toggle as={CustomToggle}>{selectedWorkstation}</Dropdown.Toggle>
              <Dropdown.Menu as={CustomMenu}>
                {workstationList.map((wstnId, i) => (
                  <Dropdown.Item
                    className="drop-down-list"
                    eventKey={wstnId}
                    key={wstnId}
                    onSelect={handleSelectWorkstationId}
                  >
                    {wstnId}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
    </div >
  );
}

import * as logUtils from '../utilities/LoggerUtilties';


export default function RefreshKeyReducer(state = 0, action) {
  const log = logUtils.getLogger();
  switch (action.type) {
    case "REFRESH_TAB":
      state += action.payload;
      log.debug("refreshing page");
      return state;
    default:
      return state;
  }
}

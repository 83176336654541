import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import './styles/index.scss';

import App from './App.jsx';
import { MsalProvider } from '@azure/msal-react';
import { store } from './store';
import { Provider } from 'react-redux';
import msalInstance from './constants/InstanceConstant';

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <MsalProvider instance={msalInstance}>
        <App />
      </MsalProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

function fetchAdminRequestAction(payload) {
    return {
      type: 'SET_ALL_ADMIN_REQUEST',
      payload: payload,
    };
  }

  function unSetAdminRequestAction(payload) {
    return {
      type: 'CLEAR_ALL_ADMIN_REQUEST',
      payload: payload,
    };
  }

  export {
    fetchAdminRequestAction,
    unSetAdminRequestAction
  }
import { workstationProblemReportAction, workstationProblemReportAddAction } from '../reduxActions/workstationProblemReportActions'
import ServiceRequestorClient from './ServiceRequestorClient';

export default new (class WorkstationProblemReport {

    /** creates a new workstationProblemReport */
    async createWorkstationProblemReport(workstationProblemReport) {
        return await ServiceRequestorClient.makeRequestToService(
            "POST", "/reports/workstations", workstationProblemReport, workstationProblemReportAddAction,
            () => {
                alert("Problem creating workstation report")
            }
        )
    }

    /** fetches all workstationProblemReports */
    async getAllWorkstationProblemReports() {
        return await ServiceRequestorClient.makeRequestToService(
            "GET", "/reports/workstations", null, workstationProblemReportAction
        )
    }
       /** fetches workstations by id */
       async getSingleWorkstationProblemReport(reportId) {
        return await ServiceRequestorClient.makeRequestToService(
            "GET", `/reports/workstations/${reportId}`, null, null
        )
    }

    /**edit single worstationProblemReport by status */
    async editSingleWorkstationProblemReportStatusById(reportId, workstationProblemReport) {
        return await ServiceRequestorClient.makeRequestToService(
            "PATCH", `/reports/workstations/${reportId}`, workstationProblemReport, null
        )
    }
})
const initialState = {
  showEditReservationConflictingModal: false
};

export default function workstationTimeslotReducer(state = initialState, action) {
  switch (action.type) {
    case "TOGGLE_EDIT_RESERVATION_SELECTION_CONFIRMATION_MODAL":
      // return Object.assign({}, state, { showEditReservationConfirmationModal: action.payload });
      return {...state, showEditReservationConfirmationModal: action.payload}
    case "TOGGLE_EDIT_RESERVATION_SELECTION_CONFLICTING":
      // return Object.assign({}, state, { showEditReservationConflictingModal: action.payload });
      return {...state, showEditReservationConflictingModal: action.payload}
    default:
      return state;
  }
}
import * as logUtils from '../utilities/LoggerUtilties';

const log = logUtils.getLogger();

export function allWorkstationStates (state = [], action) {
  switch (action.type) {
    case 'FETCH_ALL_WORKSTATION_STATES':
      log.debug('Workstations with various states')
      return action.payload;
    case 'CLEAR_ALL_WORKSTATION_STATES':
      return [];
    default:
      return state;
  }
}


// dispatches available pods and workstations
// on the given date to the corresponding reducer
function completePodAndWorkstationAction(payload) {
  return {
    type: 'SET_COMPLETE_POD_AND_WORKSTATION',
    payload: payload,
  };
}
// dispatches available workstations once pod is selected
function completeWorkstationListAction(payload) {
  return {
    type: 'SET_COMPLETE_WORKSTATION_LIST',
    payload: payload,
  };
}

export { completePodAndWorkstationAction, completeWorkstationListAction };

import React from 'react';

function useWorkstationGridData(serverResponse) {
    const workstations = serverResponse.data;
        const gridDataArr = []
        
        for(const i of workstations) {
          const workstationGridData = JSON.parse(i.gridData);
          workstationGridData.workstationId = i.workstationId;
          gridDataArr.push(workstationGridData);
        }
        return gridDataArr;
}

export default useWorkstationGridData;
// fetches all workstations with multiple reservation states 
// in given date range 
function fetchAllWorkstationStatesAction(payload) {
  return {
    type: 'FETCH_ALL_WORKSTATION_STATES',
    payload: payload,
  };
}

// dispatches available pods and workstations
// on the given date to the corresponding reducer
function allAvailableWorkstationAction(payload) {
  return {
    type: 'SET_ALL_AVAILABLE_WORKSTATION',
    payload: payload,
  };
}
// dispatches available workstations once pod is selected
function workstationListAction(payload) {
  return {
    type: 'SET_WORKSTATION_LIST',
    payload: payload,
  };
}
// dispatches available reservations received from back end
// from the selected date range
function allAvailableReservationAction(payload) {
  return {
    type: 'SET_ALL_AVAILABLE_RESERVATION',
    payload: payload,
  };
}

function getAllAvailableWorkstationGridData(payload) {
  return {
    type: 'SET_ALL_AVAILABLE_WORKSTATION_GRID_DATA',
    payload: payload,
  };
}

function updateMultipleWorkstationPositions(payload) {
  return {
    type: 'UPDATE_MULTIPLE_WORKSTATION_POSITIONS',
    payload: payload,
  };
}
function fetchAllInServiceWorkstationsAction(payload) {
  return {
    type: 'SET_ALL_IN_SERVICE_WORKSTATIONS',
    payload: payload,
  };
}

function unSetInServiceWorkstationsAction(payload) {
  return {
    type: 'CLEAR_ALL_IN_SERVICE_WORKSTATIONS',
    payload: payload,
  };
}

export {
  allAvailableWorkstationAction,
  workstationListAction,
  allAvailableReservationAction,
  getAllAvailableWorkstationGridData,
  updateMultipleWorkstationPositions,
  fetchAllWorkstationStatesAction,
  unSetInServiceWorkstationsAction,
  fetchAllInServiceWorkstationsAction
  
};

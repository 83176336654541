import * as logUtils from '../utilities/LoggerUtilties';

function workstationProblemReportReducer(state = [], action) {
  const log = logUtils.getLogger();
  switch (action.type) {
    case 'SET_WORKSTATION_PROBLEM_REPORTS':
      state = action.payload;
      log.debug('refreshing workstation problem report list reducer: ');
      return state;
    case 'ADD_WORKSTATION_REPORT':
      log.debug('Adding a workstation problem report to state: ');
      return [...state, action.payload]
    default:
      return state;
  }
}

export { workstationProblemReportReducer };
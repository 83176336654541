import * as logUtils from '../utilities/LoggerUtilties';

const log = logUtils.getLogger();

export function inServiceWorkstations(state = [], action) {
    switch(action.type) {
        case "SET_ALL_IN_SERVICE_WORKSTATIONS":
            return action.payload;
        case "CLEAR_ALL_IN_SERVICE_WORKSTATIONS":
            return [];
        default:
            return state;
    }
}
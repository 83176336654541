import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { Dropdown, DropdownButton, Col } from 'react-bootstrap';
import WorkstationSelector from './WorkstationSelector';

/**
 * Dropdown for selecting Pod Id
 * Parent: import where needed, 
 * When importing, pass in state variables selectedWorkstation, setSelectedWorkstation
 * Child: WorkstationSelector
 *  
 *  @returns String podId 
 *   
 */

function PodSelector(props) {

    const [selectedPod, setSelectedPod] = useState('Pods');
    const selectedWorkstation = props.selectedWorkstation;
    const setSelectedWorkstation = props.setSelectedWorkstation;
    const [selectedPodWorkstations, setSelectedPodWorkstations] = useState('');

    const workstationsList = useSelector((store) => store.completePodAndWorkstationReducer);



    const showWorkstationButton = typeof selectedPodWorkstations !== 'undefined' && selectedPodWorkstations.length > 0;



    const handleSelect = (e) => {
        setSelectedPod(e);
        setSelectedWorkstation('Workstation');
        setSelectedPodWorkstations(workstationsList[e]);
    }

    return (
        <>
        {
            typeof workstationsList !== "undefined" && Object.keys(workstationsList).length > 0
                ? <div id="display-pods" >
                    <Col >
                        <DropdownButton
                            required
                            id="dropdown-pod-menu"
                            className="wkstn-btn"
                            onSelect={handleSelect}
                            title={selectedPod}

                        >
                            {Object.keys(workstationsList).map((podId, i) => (
                                <Dropdown.Item
                                    className="drop-down-list"
                                    eventKey={podId}
                                    key={i}
                                >
                                    {podId}
                                </Dropdown.Item>
                            ))}
                        </DropdownButton>

                        {showWorkstationButton && <WorkstationSelector data-testid="workstationSelector"
                            selectedPodWorkstations={selectedPodWorkstations}
                            setSelectedWorkstation={setSelectedWorkstation}
                            selectedWorkstation={selectedWorkstation} />}
                    </Col>
                </div>
                : <div>
                    <h3>No Pods Are Available</h3>
                </div>
        }
        </>
    )
}

export default PodSelector;
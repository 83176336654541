export function addDays(currentDate, days) {
  let date = new Date(currentDate);
  date.setDate(date.getDate() + days);
  return dateToString(date);
}
// Is there a good video editor that ISN'T monthly-subscription-based?
// Like why tf is adobe premiere $25/mo for single-user non-enterprise
export function toDayOfWeekMonthShort (dateAsString) {
  const date = new Date(dateAsString.replace(/-/g, '\/'));
  // const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
  return date.toDateString().slice(0, 10);
}

export function getDays(startDate, stopDate) {
  let dateArray = [];
  let currentDate = startDate;
  while (currentDate <= stopDate) {
    dateArray.push(new Date(currentDate).toISOString().substring(0, 10));
    currentDate = addDays(currentDate, 1);
  }
  // Tue Apr 26 2022 00:00:00 GMT-0500 (Central Daylight Time)
  return dateArray;
}

export function parseDate(input) {
  let parts = input.match(/(\d+)/g);
  return new Date(parts[0], parts[1] - 1, parts[2]);
}

// use this function instead if you want formatDate
export function dateToString(input) {
  const stringDate = new Date(input).toISOString().slice(0, 10);

  return stringDate;
}

export function formatJSONDate(input) {
  let formattedDate = "";
  if (input.monthValue > 9) {
    formattedDate = formattedDate.concat(
      input.year + "-" + input.monthValue + "-" + input.dayOfMonth
    );
  }

  // Pad months below October with a zero i.e. March would be 03 and not 3
  else {
    formattedDate = formattedDate.concat(
      input.year + "-0" + input.monthValue + "-" + input.dayOfMonth
    );
  }

  return formattedDate;
}

/*use for 1-week limit on res length */
export function setEndDateToStartDatePlusSeven(startDate) {
  let dateArray = startDate.split("-");
  let maxEndDate = new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);
  maxEndDate.setDate(maxEndDate.getDate() + 6);
  return maxEndDate.toISOString().substring(0, 10);
}

export function setEndDateToStartDatePlusEight(startDate) {
  let dateArray = startDate.split("-");
  let maxEndDate = new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);
  maxEndDate.setDate(maxEndDate.getDate() + 7);
  return maxEndDate.toISOString().substring(0, 10);
}

/*use for 1-week limit on res length */
export function setStartDateToSevenMinusEndDateOrToday(endDate) {
  let dateArray = endDate.split("-");
  let minStartDate = new Date(dateArray[0], dateArray[1] - 1, dateArray[2]);
  minStartDate.setDate(minStartDate.getDate() - 7);
  if (minStartDate > new Date()) {
    return minStartDate.toISOString().substring(0, 10);
  } else {
    return new Date().toISOString().substring(0, 10);
  }
}

export function modifySingleDateCharacters(input) {
  let startDate = input.startDate.split("-");
  if (startDate[1].length == 1) {
    startDate[1] = "0" + startDate[1];
  }
  if (startDate[2].length == 1) {
    startDate[2] = "0" + startDate[2];
  }
  input.startDate = startDate.join("-");
  let endDate = input.endDate.split("-");
  if (endDate[1].length == 1) {
    endDate[1] = "0" + endDate[1];
  }
  if (endDate[2].length == 1) {
    endDate[2] = "0" + endDate[2];
  }
  input.endDate = endDate.join("-");
}

export function isLongTermReservation(startDate, endDate) {
  let startdateArray = startDate.split("-");
  let enddateArray = endDate.split("-");
  let minStartDate = new Date(startdateArray[0], startdateArray[1] - 1, startdateArray[2]);
  let maxStartDate = new Date(enddateArray[0], enddateArray[1] - 1, enddateArray[2]);
  const days = (maxStartDate - minStartDate) / (1000 * 3600 * 24)
  return days > 6
}



import { Logger } from 'sass';
import * as logUtils from '../utilities/LoggerUtilties';

function allReservationReducer(state = [], action) {
  const log = logUtils.getLogger();
  switch (action.type) {
    case 'SET_ALL_RESERVATION':
      state = action.payload;
      log.debug('refreshing all reservation list reducer: ');
      return state;

    case 'CREATE_RESERVATION':
      return [
        ...state,
        ...action.payload
      ]

    case 'EDIT_RESERVATION':
      let updatedReservation

      if (action.payload.hasOwnProperty(true)) {
        updatedReservation = action.payload[true][0]
      }

      else {
        updatedReservation = action.payload
      }

      const updatedState = state.map(reservation => {

        if (reservation.reservationId === updatedReservation.reservationId) {

          return {
            ...updatedReservation
          }
        }

        return reservation
      })

      return updatedState

    case 'DELETE_RESERVATION':
      let deleteResState = state.filter(reservation => reservation.reservationId !== action.payload.reservationId)
      return deleteResState;

    default:
      return state;
  }
}

function allShortTermReservationReducer(state = [], action) {
  const log = logUtils.getLogger();
  switch (action.type) {
    case 'SET_ALL_SHORT_TERM_RESERVATION':
      state = action.payload;
      log.debug('refreshing all short term reservation list reducer: ');
      return state;
    default:
      return state;
  }
}
function allLongTermReservationReducer(state = [], action) {
  const log = logUtils.getLogger();
  switch (action.type) {
    case 'SET_ALL_LONG_TERM_RESERVATION':
      state = action.payload;
      log.debug('refreshing all long term reservation list reducer: ');
      return state;
    default:
      return state;
  }
}

function longTermReservationRequests(state = [], action) {
  const log = logUtils.getLogger();
  switch (action.type) {
    case 'SET_LONG_TERM_RESERVATION_REQUESTS':
      return action.payload;
    case 'UNSET_LONG_TERM_RESERVATION_REQUESTS':
      return [];
    default:
      return state;
  }
}

function allPermanentReservationReducer(state = [], action) {
  const log = logUtils.getLogger();
  switch (action.type) {
    case 'SET_ALL_PERMANENT_RESERVATION':
      state = action.payload;
      log.debug('refreshing all permanent reservation list reducer: ');
      return state;
    default:
      return state;
  }
}

function myReservationReducer(state = [], action) {
  const log = logUtils.getLogger();
  switch (action.type) {
    case 'SET_MY_RESERVATION':
      state = action.payload;
      log.debug('refreshing my reservation list reducer: ');
      return state;

    case 'CREATE_MY_RESERVATION':

      return [
        ...state,
        ...action.payload
      ]

    case 'EDIT_MY_RESERVATION':
      let updatedReservation

      if (action.payload.hasOwnProperty(true)) {
        updatedReservation = action.payload[true][0]
      }

      else {
        updatedReservation = action.payload
      }

      const updatedState = state.map(reservation => {

        if (reservation.reservationId === updatedReservation.reservationId) {

          return {
            ...updatedReservation
          }
        }

        return reservation
      })

      return updatedState

    case 'DELETE_MY_RESERVATION':
      let deleteResState = state.filter(reservation => reservation.reservationId !== action.payload.reservationId);
      return deleteResState;
    default:
      return state;
  }
}

function myLongTermReservationReducer(state = [], action) {
  const log = logUtils.getLogger();
  switch (action.type) {
    case 'SET_MY_LONG_TERM_RESERVATION':
      state = action.payload;
      log.debug('refreshing my long term reservation list reducer: ');
      return state;
    default:
      return state;
  }
}

function myPermanentReservationReducer(state = [], action) {
  switch (action.type) {
    case 'SET_MY_PERMANENT_RESERVATION':
      state = action.payload;
      return state;
    default:
      return state;
  }
}

export {
  allReservationReducer,
  allLongTermReservationReducer,
  longTermReservationRequests,
  allPermanentReservationReducer,
  myLongTermReservationReducer,
  myReservationReducer,
  myPermanentReservationReducer,
  allShortTermReservationReducer
};

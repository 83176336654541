import { completeWorkstationListAction } from '../reduxActions/editWorkstationActions';
import { updateMultipleWorkstationPositions } from '../reduxActions/workstationActions'
import { fetchAllInServiceWorkstationsAction } from '../reduxActions/workstationActions';
import ServiceRequestorClient from './ServiceRequestorClient';


/** Handles creation/deleting/editing and multiple fetching options of workstations
 * Interacts with workstation collection through backend
 */
export default new (class WorkstationService {

  /** gets all workstations with reservations states */
  async getAllWorkstationStates(startDate, endDate) {
    return await ServiceRequestorClient.makeRequestToService(
      "GET", `/workstations/states?startDate=${startDate}&endDate=${endDate}`, null, null
    )
  }

  /** creates a list of new workstations, param list of workstation objects */
  async createWorkstations(workstations) {
    return await ServiceRequestorClient.makeRequestToService(
      "POST", "/workstations", workstations, null, (error) => {
        if (error.response.status === 409) {
          alert("You have conflicting workstation names")
        }
      }
    )
  }

  //Get all workstations
  async getWorkstations(ids, inService) {
    if (inService === null) {
      return await ServiceRequestorClient.makeRequestToService(
        "GET", `/workstations?id=${ids}&inService=`, null, null
      )
    }
    else if (ids === null) {
      return await ServiceRequestorClient.makeRequestToService(
        "GET", `/workstations?inService=${inService}`, null, (inService ? fetchAllInServiceWorkstationsAction : null)
      )
    }
    else {
      return await ServiceRequestorClient.makeRequestToService(
        "GET", `/workstations?id=${ids}&inService=${inService}`, null, null)

    }
  }

  //Get all workstations
  async getAllWorkstations() {

    return await ServiceRequestorClient.makeRequestToService(
      "GET", "/workstations", null, completeWorkstationListAction
    )
  }


  /** Deletes workstations via IDs */
  async deleteWorkstationListById(ids) {
    const idsAsString = ids.toString();
    return await ServiceRequestorClient.makeRequestToService(
      "DELETE", `/workstations?workstationIds=${idsAsString}`, null, null
    )
  }

  /** Bulk edits workstations by Ids */
  async editWorkstationListById(ids, workstation) {
    return await ServiceRequestorClient.makeRequestToService(
      "PATCH", `/workstations?ids=${ids}`, workstation, null
    )
  }

  /** Edits an multiple workstation, param workstation object */
  async editGridDataListById(gridData) {
    return await ServiceRequestorClient.makeRequestToService(
      "PATCH", '/workstations/coordinates', gridData, null
    )
  }

  /** Gets all workstations that fall in between dates of each param */
  async getAllAvailableWorkstation(startDate, endDate) {
    return await ServiceRequestorClient.makeRequestToService(
      "GET", `/workstations/availableWorkstations?startDateRange=${startDate}&endDateRange=${endDate}`, null, null
    )
  }

  /** Gets all available workstation timeslots for single workstation by the id param
   *  between start and end date params
   */
  async getAvailableDates(startDate, endDate, workstationId) {
    return await ServiceRequestorClient.makeRequestToService(
      "GET", `/workstations/availableDates?startDate=${startDate}&endDate=${endDate}&workstationId=${workstationId}&`, null, null
    )
  }
})();

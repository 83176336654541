import React, { useEffect, useState } from 'react';

import { Modal, Container, Form, Col } from 'react-bootstrap';
import '../styles/pages/ConfirmAdminRequestModal.scss';
import WebsiteProblemReportService from '../reduxServices/WebsiteProblemReportService';
import { browserName, isMobile } from 'react-device-detect';
import ServiceRequestorClient from '../reduxServices/ServiceRequestorClient';

/** Confirmation modal for accepting admin requests
 *  @component
 */
function WebsiteReportForm(props) {
    const [description, setDescription] = useState('')
    const [deviceType, setDeviceType] = useState('')
    const [browserType, setBrowserType] = useState('')
    const [locationInWebsite, setLocationInWebsite] = useState('')

    async function closeModal() {
        props.close()
    }

    useEffect(() => {
        // When the modal is open, auto-populate the device and browser type form fields
        
            if (isMobile) {
                setDeviceType('Mobile')
            }

            else {
                setDeviceType('Desktop')
            }

            setBrowserType(browserName)
        
    }, [])

    
    /** Creates website report on form submit */
    const onSubmit = (e) => {
        e.preventDefault()
        if (!description || !deviceType || !browserType || !locationInWebsite) {
            alert('please fill all fields');
            return;
        }

        const userInfo = ServiceRequestorClient.getUserInfo()

        const requestBody = {
            "fname": userInfo["fname"],
            "lname": userInfo["lname"],
            "userEmail": userInfo["adminEmail"],
            "description": description,
            "status": "New",
            "deviceType": deviceType,
            "browserType": browserType,
            "locationInWebsite": locationInWebsite
        }

        WebsiteProblemReportService.createWebsiteReport(requestBody)
        setDescription('');
        setDeviceType('');
        setBrowserType('');
        setLocationInWebsite('');
        closeModal()
    };

    return (
                <div>
                    <Container fluid>
                        <Form onSubmit={onSubmit} className="admin-form">
                            <Col className="form-container" md={10}>
                                <div className="form-inputs">
                                    <div className="name-container">
                                        <div className="fname-container">
                                            <p className="name-label">Device Type</p>
                                            <Form.Row
                                                id="input"
                                                className="navtabs"
                                                onChange={(e) => setDeviceType(e.target.value)}
                                            >
                                                <Form.Control required type="text" value={deviceType} />
                                            </Form.Row>
                                        </div>

                                        <div className="lname-container">
                                            <p className="name-label">Browser Type</p>
                                            <Form.Row
                                                id="input"
                                                className="navtabs"
                                                onChange={(e) => setBrowserType(e.target.value)}
                                            >
                                                <Form.Control required type="text" value={browserType} />
                                            </Form.Row>
                                        </div>
                                    </div>

                                    <div className="email-container">
                                        <p className="email-label">Description</p>
                                        <Form.Row
                                            id="input"
                                            className="navtabs"
                                            onChange={(e) => setDescription(e.target.value)}
                                        >
                                            <Form.Control
                                                required
                                                className="form-control"
                                                value={description}
                                                as="textarea"
                                                rows={3}
                                            />
                                        </Form.Row>
                                    </div>

                                    <div className="email-container">
                                        <p className="email-label">Affected Page</p>
                                        <Form.Row
                                            id="input"
                                            className="navtabs"
                                            onChange={(e) => setLocationInWebsite(e.target.value)}
                                        >
                                            <Form.Control
                                                required
                                                type="text"
                                                className="form-control"
                                                value={locationInWebsite}
                                            />
                                        </Form.Row>
                                    </div>

                                </div>

                                <div className="buttonContainer">
                                    <button
                                        id="save-button"
                                        className="wkstn-btn primary-btn"
                                        type="submit"
                                    >
                                        Submit
                                    </button>
                                </div>
                            </Col>
                        </Form>
                    </Container>
                </div>
    );
}

export default WebsiteReportForm;

import { adminAction } from '../reduxActions/userActions';
import ServiceRequestorClient from './ServiceRequestorClient';
import { fetchAdminRequestAction } from '../reduxActions/adminRequestActions';

/** Handles admin operations */
export default new (class AdminService {

  /** save an adminUser object to the db */
  async createAdminUser(adminUser) {
    return await ServiceRequestorClient.makeRequestToService(
      "POST", "/admins/users", adminUser, null
    )
  }
  /** adds an admin user to the request collection */
  async createAdminRequest() {
    const adminUser = ServiceRequestorClient.getUserInfo()
    return await ServiceRequestorClient.makeRequestToService(
      "POST", "/admins/requests", adminUser, null
    )
  }
  /** save an adminUser object to the db */
  async approveAdminRequest(adminUser) {
    return await ServiceRequestorClient.makeRequestToService(
      "POST", "/admins/approve", adminUser, null
    )
  }

  /** deletes admin user */
  async removeAdminRequest(adminRequest) {
    return ServiceRequestorClient.makeRequestToService(
      "DELETE", "/admins/requests",
      adminRequest, null
    )
  }

  /** lookup by email */
  async getAdminByEmail(userEmail) {
    return await ServiceRequestorClient.makeRequestToService(
      "GET", `/admins/users?userEmail=${userEmail}`, null, adminAction
    )
  }

  async getAdminRequestById() {
    return await ServiceRequestorClient.makeRequestToService(
      "GET", `/admins/requests/${requestId}`, null, adminAction
    )
  }

  /** retrieves all admin requests (adminUser objects) */
  async getAdminRequests() {
    return await ServiceRequestorClient.makeRequestToService(
      "GET", "/admins/requests", null, fetchAdminRequestAction
    )
  }

})();

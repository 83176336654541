import * as logUtils from '../utilities/LoggerUtilties';

export default function editModeReducer(state = false, action) {
    const log = logUtils.getLogger();
    switch (action.type) {
      case 'SWITCH_EDIT':
        state = action.payload
        return state;
      default:
        return state;
    }
  }

  export { editModeReducer };

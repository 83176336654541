import React from 'react';
import { Button, Image } from 'react-bootstrap';
import '../styles/pages/SignInCard.scss';
import { useMsal } from '@azure/msal-react';
import { loginRequest } from '../authConfig';
import * as logUtils from '../utilities/LoggerUtilties';

/**
 * Renders a button which, when selected, will redirect the page to the login prompt
 * @component
 */
const SignInCard = () => {
  const { instance } = useMsal();
  var log = logUtils.getLogger();

  function getAccessToken(instance) {
    instance
      .loginRedirect(loginRequest)
      .then((response) => response.json())
      .then((data) => {
        console.debug('Sign attempt response :' + response);
        return data;
      })
      .catch((error) => {
        log.error('Failed to sign in with error :' + error);
      });
  }

  return (
    <div className="card-box">
      <div className="container">
        <div className="sign-in-logo-div">
          <Image
            src="dibs_Logo-Color.png"
            rounded
            className="sign-in-logo-image"
          />
        </div>
          <div className='card-box-text'>
            Reserve a workstation, <br/>stay for a while.
          </div>
        <div className='card-box-button-container'>
          <button
            className="sign-in-btn"
            onClick={() => getAccessToken(instance)}
          >
            Get Started →
          </button>
        </div>
      </div>
    </div>
  );
};
export default SignInCard;

import * as logUtils from '../utilities/LoggerUtilties';

function allPermanentReservationsReducer(state = [], action) {
    const log = logUtils.getLogger();
    switch (action.type) {
        case 'SET_ALL_PERMANENT_RESERVATIONS':
            state = action.payload;
            log.debug('refreshing all permanent reservation list reducer: ');
            return state;
        case 'CLEAR_ALL_PERMANENT_RESERVATIONS':
            return [];
        default:
            return state;
    }
}

function permanentReservationRequests(state = [], action) {
    const log = logUtils.getLogger();
    switch (action.type) {
        case 'SET_PERMANENT_RESERVATION_REQUESTS':
            return action.payload;
        case 'UNSET_PERMANENT_RESERVATION_REQUESTS':
            return [];
        default:
            return state;
    }
}

export {
    allPermanentReservationsReducer,
    permanentReservationRequests
};

import {
  myReservationAction,
  allReservationAction,
  createReservation,
  createMyReservation,
  editReservationAction,
  editMyReservationAction,
  deleteReservation,
  deleteMyReservation
} from '../reduxActions/reservationActions';
import { userListAction } from '../reduxActions/userActions';
import ServiceRequestorClient from './ServiceRequestorClient';
import { store } from '../store';

export default new (class ReservationService {

  async getAllReservations(userEmail) {
    const url = userEmail ? `/reservations?userEmail=${userEmail}` : "/reservations";
    const action = userEmail ? myReservationAction : allReservationAction

    return await ServiceRequestorClient.makeRequestToService(
      "GET", url, null, action
    )
  }

  async getAllReservationRequests() {
    return await ServiceRequestorClient.makeRequestToService(
      "GET", "/reservations/requests", null, null
    )
  }

  async createReservations(reservations, userEmail) {
    return await ServiceRequestorClient.makeRequestToService(
      "POST", "/reservations", reservations, null,
      (error) => {
        throw error
      }
    )
      .then(response => {
        // If there's only one reservation in the list, it can be of all three types: short, long, and
        // permanent. So, we will check if it is approved before sending it off to allReservation or
        // myReservation reducer

        if (response.data.length === 1) {
          if (response.data[0].reservationType === 'short') {
            store.dispatch(createReservation(response.data))

            if (userEmail) {
              store.dispatch(createMyReservation(response.data))
            }
          }
        }

        return response
      })
  }

  async checkRequestConflict(permanentReservationRequest) {
    return await ServiceRequestorClient.makeRequestToService(
      "POST", "/reservations/permanent/conflict", permanentReservationRequest, null, (error) => {
        throw error
      })
  }

  async acceptReservation(reservation, description) {
    let url = description ? `/reservations/accept?description=${description}` : "/reservations/accept";
    return await ServiceRequestorClient.makeRequestToService(
      "PUT", url, reservation, null,
      (error) => {
        throw error
      }
    ).then(response => {
      this.getAllReservations();
      return response;
    })
  }

  async getAllUsers() {
    return await ServiceRequestorClient.makeRequestToService(
      "GET", "/reservations/allUsers", null, userListAction
    )
  }

  async cancelReservation(reservation) {
    return await ServiceRequestorClient.makeRequestToService(
      "DELETE", "/reservations", reservation, null
    )
      .then(response => {
        if (response.status === 204) {
          store.dispatch(deleteReservation(reservation))
          store.dispatch(deleteMyReservation(reservation))
        }

        this.getAllReservations()
        return response
      })
  }

  async rejectReservation(reservation, description) {
    return await ServiceRequestorClient.makeRequestToService(
      "DELETE", `/reservations/reject?description=${description}`, reservation, null
    )
  }

  async editReservation(reservation, newDataType, newData) {
    return await ServiceRequestorClient.makeRequestToService(
      "PATCH", `/reservations?newDataType=${newDataType}&newData=${newData}`, reservation, editReservationAction,
      (error) => {
        throw error
      }
    )
      .then(response => {
        this.getAllReservations()
        store.dispatch(editMyReservationAction(response.data))
        return response
      })
  }

  async updateConflictReservation(serializedBatchUpdate) {
    return await ServiceRequestorClient.makeRequestToService(
      "POST", '/reservations/replace', serializedBatchUpdate, null
    )
      .then(response => {
        this.getAllReservations()
        return response
      })
  }
})();

import { configureStore } from '@reduxjs/toolkit';
import reservationFormReducer from './reducers/ReservationFormReducer';
import workstationTimeslotReducer from './reducers/WorkstationTimeslotReducer';
import loginReducer from './reducers/LoginReducer';
import refreshKeyReducer from './reducers/RefreshKeyReducer';
import {
  myReservationReducer,
  myLongTermReservationReducer,
  allReservationReducer,
  allLongTermReservationReducer,
  allPermanentReservationReducer,
  myPermanentReservationReducer,
  allShortTermReservationReducer,
  longTermReservationRequests
} from './reducers/ReservationListReducer';
import {
  adminReducer,
  userListReducer,
  LTuserListReducer,
} from './reducers/UserReducer';
import {
  allAvailableWorkstationReducer,
  workstationListReducer,
  allAvailableReservationReducer,
  getAllAvailableWorkstationGridDataReducer,
} from './reducers/AllAvailableWorkstationReducer';
import {
  completePodAndWorkstationReducer,
  completeWorkstationListReducer,
} from './reducers/EditWorkstationReducer';
import {
  adminAllAvailableWorkstationReducer,
  adminWorkstationListReducer,
} from './reducers/AdminAllAvailableWorkstationReducer';
import {
  editModeReducer
} from './reducers/EditModeReducer';
import { allWorkstationStates } from './reducers/workstationStatesReducer';


import { websiteProblemReportReducer } from './reducers/WebsiteProblemReportReducer'

import {
  workstationProblemReportReducer
} from './reducers/WorkstationProblemReportListReducer';

import { allPermanentReservationsReducer, permanentReservationRequests } from './reducers/PermanentReservationReducer';
import { adminRequestReducer } from './reducers/AdminRequestReducer';
import { inServiceWorkstations } from './reducers/InServiceWorkstationsReducer'

export const store = configureStore({
  reducer: {
    modal: reservationFormReducer,
    authToken: loginReducer,
    workstationTimeslotReducer: workstationTimeslotReducer,
    refreshKeyReducer: refreshKeyReducer,
    myReservationReducer: myReservationReducer,
    myLongTermReservationReducer: myLongTermReservationReducer,
    allLongTermReservationReducer: allLongTermReservationReducer,
    allReservationReducer: allReservationReducer,
    adminReducer: adminReducer,
    userListReducer: userListReducer,
    allAvailableWorkstationReducer: allAvailableWorkstationReducer,
    workstationListReducer: workstationListReducer,
    allWorkstationStates: allWorkstationStates,
    allAvailableReservationReducer: allAvailableReservationReducer,
    completePodAndWorkstationReducer: completePodAndWorkstationReducer,
    completeWorkstationListReducer: completeWorkstationListReducer,
    adminAllAvailableWorkstationReducer: adminAllAvailableWorkstationReducer,
    adminWorkstationListReducer: adminWorkstationListReducer,
    LTuserListReducer: LTuserListReducer,
    editModeReducer: editModeReducer,
    longTermReservationRequests: longTermReservationRequests,
    getAllAvailableWorkstationGridDataReducer: getAllAvailableWorkstationGridDataReducer,

    websiteProblemReportReducer: websiteProblemReportReducer,
    workstationProblemReportReducer: workstationProblemReportReducer,
    myPermanentReservationReducer: myPermanentReservationReducer,
    allPermanentReservationsReducer: allPermanentReservationsReducer,
    permanentReservationRequests: permanentReservationRequests,
    allPermanentReservationReducer: allPermanentReservationReducer,
    allShortTermReservationReducer: allShortTermReservationReducer,
    adminRequestReducer: adminRequestReducer,
    inServiceWorkstations: inServiceWorkstations,
  },

  devTools: true
});

import { filterReservationsByType } from '../utilities/ReservationUtilities';

function allReservationAction(payload) {
  return {
    type: 'SET_ALL_RESERVATION',
    payload: payload,
  };
}

function createReservation(payload) {
  return {
    type: 'CREATE_RESERVATION',
    payload: payload,
  };
}

function editReservationAction(payload) {
  return {
    type: 'EDIT_RESERVATION',
    payload: payload,
  };
}

function editMyReservationAction(payload) {
  return {
    type: 'EDIT_MY_RESERVATION',
    payload: payload,
  };
}

function deleteReservation(payload) {
  return {
    type: 'DELETE_RESERVATION',
    payload: payload,
  };
}

function allShortTermReservationAction(payload) {
  return {
    type: 'SET_ALL_SHORT_TERM_RESERVATION',
    payload: payload,
  };
}

function allLongTermReservationAction(payload) {
  return {
    type: 'SET_ALL_LONG_TERM_RESERVATION',
    payload: payload,
  };
}

function setLongTermReservationRequestsAction (payload) {
  return {
    type: 'SET_LONG_TERM_RESERVATION_REQUESTS',
    payload: payload,
  };
}

function allPermanentReservationAction(payload) {
  return {
    type: 'SET_ALL_PERMANENT_RESERVATION',
    payload: payload,
  };
}

function myReservationAction(payload) {
  return {
    type: 'SET_MY_RESERVATION',
    payload: payload,
  };
}

function createMyReservation(payload) {
  return {
    type: 'CREATE_MY_RESERVATION',
    payload: payload,
  };
}


function deleteMyReservation(payload) {
  return {
    type: 'DELETE_MY_RESERVATION',
    payload: payload,
  };
}

function myLongTermReservationAction(payload) {
  return {
    type: 'SET_MY_LONG_TERM_RESERVATION',
    payload: payload,
  };
}

function setMyPermanentReservationAction(payload) {
  return {
    type: 'SET_MY_PERMANENT_RESERVATION',
    payload: payload,
  };
}

export {
  myReservationAction,
  myLongTermReservationAction,
  allReservationAction,
  allLongTermReservationAction,
  allPermanentReservationAction,
  setMyPermanentReservationAction,
  allShortTermReservationAction,
  setLongTermReservationRequestsAction,

  createReservation,
  createMyReservation,
  editReservationAction,
  editMyReservationAction,
  deleteReservation,
  deleteMyReservation
};

const initialState = {
    accessTokenState: "",
  };
  
  export default function loginReducer(state = initialState, action) {
    switch (action.type) {
      case "SAVE_AUTH_TOKEN":
        return Object.assign({}, state, { accessTokenState: action.payload });
      default:
        return state;
    }
  }
  
import * as logUtils from '../utilities/LoggerUtilties';

export function completePodAndWorkstationReducer(state = [], action) {
  const log = logUtils.getLogger();

  switch (action.type) {
    case 'SET_COMPLETE_POD_AND_WORKSTATION':
      log.debug('Setting podMapsToWorkstationReducer...');
      return action.payload;
    case 'CLEAR_COMPLETE_POD_AND_WORKSTATION':
      log.debug('Clearing podMapsToWorkstationReducer...');
      return [];
    default:
      return state;
  }
}

export function completeWorkstationListReducer(state = [], action) {
  const log = logUtils.getLogger();

  switch (action.type) {
    case 'SET_COMPLETE_WORKSTATION_LIST':
      log.debug('Setting complete workstationListReducer...');
      return action.payload;
    case 'CLEAR_COMPLETE_WORKSTATION_LIST':
      log.debug('Clearing complete workstationListReducer...');
      return [];
    default:
      return state;
  }
}

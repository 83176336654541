import React, { useState } from 'react';
import { Dropdown, DropdownButton, Modal } from 'react-bootstrap';
import '../styles/pages/CombinedReportModal.scss';
import WebsiteReportForm from './WebsiteProblemReportForm';
import WorkstationProblemReportForm from './WorkstationProblemReportForm';



const CombinedReportModal = (props) => {

    const [issueType, setIssueType] = useState('Select an Issue Type')

    async function closeModal() {
        //setSelectedWorkstation('Workstation');
        props.setShowCombinedReportModal(false)
        setIssueType('Select an Issue Type')
    }
    return (

        <Modal
            id="hide-modal"
            className="hide-modal"
            show={props.showCombinedReportModal}
            onHide={closeModal}
            size="m"
            dialogClassName="issue-modal"
        >


            <Modal.Header closeButton >
                <h3 id="combined-modal-header" style={{ textAlign: "center" }}>Report a Problem</h3>
            </Modal.Header>
            <Modal.Body className={`modal-min-height-control ${issueType === 'Select an Issue Type' ? null : 'modal-height-control'}`}>
                <DropdownButton
                    data-testid="dropdown-menu"
                    id="dropdown-pod-menu"
                    className="issue-btn issue-type-dropdown"
                    title={issueType}
                >
                    <Dropdown.Item onClick={() => { setIssueType('Workstation Issue') }}>Workstation Issue</Dropdown.Item>
                    <Dropdown.Item onClick={() => { setIssueType('Website Issue') }}>Website Issue</Dropdown.Item>

                </DropdownButton>
                {issueType == 'Select an Issue Type'
                    ?
                    null
                    :
                    <>
                        {issueType === 'Website Issue'
                            ?

                            <WebsiteReportForm close={closeModal} />
                            :

                            <WorkstationProblemReportForm close={closeModal} />
                        }
                    </>
                }


            </Modal.Body>
        </Modal>
    )
}
export default CombinedReportModal;
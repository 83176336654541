import React, { useState, useEffect, useRef } from 'react';

import '../styles/pages/HamburgerMenu.scss';
import { FaBars } from 'react-icons/fa';
import { useMsal } from '@azure/msal-react';
import { connect } from 'react-redux';
import { MobileView } from 'react-device-detect';
import AdminService from "../reduxServices/AdminService";
import ReservationService from "../reduxServices/ReservationService";
import WorkstationProblemReportService from "../reduxServices/WorkstationProblemReportService";
import WebsiteProblemReportService from "../reduxServices/WebsiteProblemReportService";
import WorkstationService from "../reduxServices/WorkstationService";
import ServiceRequestorClient from "../reduxServices/ServiceRequestorClient";
import { filterReservationsByType } from "../utilities/ReservationUtilities";
import {
  allLongTermReservationAction,
  allPermanentReservationAction,
  allShortTermReservationAction,
  setLongTermReservationRequestsAction,
} from "../reduxActions/reservationActions";
import { setPermanentReservationRequests } from "../reduxActions/permanentReservationActions";

/** Menu icon with dropdown for sign-out and admin request if user is non-admin
 * @component
 * @param {*} props
 * @returns Menu Dropdown
 */
function HamburgerMenu(props) {
  // adminObject is coming from reducer
  const adminObject = props.adminObject;
  const [menuActive, setMenuActive] = useState(false);
  const { instance } = useMsal();
  const ref = useRef(null);

  //enable click-anywhere-outside to close menu
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  const fetchReservations = async () => {
    ReservationService.getAllReservations().then((response) => {
      props.setAllShortTermReservations(
        filterReservationsByType(response.data, "short")
      );

      props.setAllLongTermReservations(
        filterReservationsByType(response.data, "long")
      );

      props.setAllPermanentReservations(
        filterReservationsByType(response.data, "permanent")
      );
    });

  };

  const handleMenu = (toggle) => {
    if (toggle) {
      setMenuActive(!menuActive);
    } else {
      setMenuActive(false);
    }
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      handleMenu(false);
    }
  };

  const handleMobileClick = (component) => {
    props.setActiveMobileComponent(component)
    handleMenu(false);
 
    switch (component) {
      case "AdminReportViewer":
        WebsiteProblemReportService.getAllWebsiteProblemReports();
        WorkstationProblemReportService.getAllWorkstationProblemReports();

        return;
      case "AllAvailableWorkstationForm":
        fetchReservations();
        return;
      case "MobileConsolidatedView":
        ReservationService.getAllReservations(ServiceRequestorClient.getUserInfo().adminEmail);
        WorkstationService.getWorkstations(null, true);
        return;
      case "UserAllReservationsView":
        WorkstationService.getWorkstations(null, true);
        ReservationService.getAllUsers();
        return;
      case "AdminAllAvailableWorkstationForm":
        AdminService.getAdminRequests()
        ReservationService.getAllReservationRequests().then(response => {
          props.setLongTermReservationRequests(filterReservationsByType(response.data, "long"));
        });
        ReservationService.getAllReservationRequests().then((response) => {
          props.setPermanentReservationRequests(filterReservationsByType(response.data, "permanent"));
        });
        return;
      case "editWkstnView":
        return;
      default:
        return;
    }
  };

  let hamburgerContent

  if (adminObject?.length === 0) {
    hamburgerContent = (
      <>
        <li
          id="request-admin"
          className="request-admin"
          onClick={() => props.requestAdmin()}
        >
          Request Admin Access
        </li>
        <li className="divider"></li>
      </>
    )
  }

  return (
    <div ref={ref} className="hamburger-dropdown">
      <div
        id="hamburger-icon"
        className="dropdown-icon"
        onClick={() => handleMenu(true)}
      >
        <FaBars size={25} style={{ color: '#cccccc', cursor: 'pointer' }} />
      </div>

      {menuActive ? (
        <ul className="dropdown-content">
          {hamburgerContent}
          {/* <li className="divider"></li>   */}
          <li
            id="report-issue"
            className="report-issue"
            onClick={() => props.setShowCombinedReportModal(true)}
          >
            Report Issue
          </li>
          <MobileView>
          <li className="divider"></li>
          <li
            id="report-issue"
            className="report-issue"
            onClick={() => handleMobileClick("AllAvailableWorkstationForm")}
          >
            Create Reservation
          </li>
          <li className="divider"></li>
          <li
            id="report-issue"
            className="report-issue"
            onClick={() => handleMobileClick("MobileConsolidatedView")}
          >
            My Reservations
          </li>
          <li className="divider"></li>
          <li
              id="report-issue"
              className="report-issue"
              onClick={() => handleMobileClick("UserAllReservationsView")}
            >
              All Reservations
          </li>
          {adminObject.length >= 1 
            ?
            <>
            <li className="divider"></li>
            <li
              id="report-issue"
              className="report-issue"
              onClick={() => handleMobileClick("AddAdminForm")}
            >
              Admin Controls
            </li>
            <li className="divider"></li>
            <li
              id="report-issue"
              className="report-issue"
              onClick={() => handleMobileClick("AdminAllAvailableWorkstationForm")}
            >
              All Reservations
            </li>
            {/* <li className="divider"></li>
            <li
              id="report-issue"
              className="report-issue"
              onClick={() => handleMobileClick("WorkstationGrid")}
            >
              Edit Workstations (not mobile optimized)
            </li> */}
            <li className="divider"></li>
            <li
              id="report-issue"
              className="report-issue"
              onClick={() => handleMobileClick("AdminReportViewer")}
            >
              Admin Reports
            </li>
            </>
            :
            null
          }
         
          </MobileView>
          <li className="divider"></li>
          <li
            id="sign-out"
            className="sign-out"
            onClick={() => props.handleLogout(instance)}
          >
            Sign Out
          </li>
        </ul>
      ) : (
        <></>
      )}
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    reservationList: state.reservationListReducer,
    adminObject: state.adminReducer,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {

    // New Dispatches
    setAllShortTermReservations: (response) =>
      dispatch(allShortTermReservationAction(response)),
    setAllLongTermReservations: (response) =>
      dispatch(allLongTermReservationAction(response)),
    setAllPermanentReservations: (response) =>
      dispatch(allPermanentReservationAction(response)),
    setLongTermReservationRequests: (response) => 
      dispatch(setLongTermReservationRequestsAction(response)),
    setPermanentReservationRequests: (response) =>
      dispatch(setPermanentReservationRequests(response)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(HamburgerMenu);
import axios from 'axios';
import msalInstance from '../constants/InstanceConstant';
import { loginRequest } from '../authConfig';
import correlationId from '../constants/CorrelationIdConstant';
import * as logUtils from '../utilities/LoggerUtilties';
import { store } from '../store';

/**
 * Utility class which handles the sending of requests to the backend after checking
 * for session and token validity. This class also contains other helpful methods such
 * as ones which return the currently logged in user's information (getUserInfo())
 */
export default new (class ServiceRequestor {
    constructor() {
        this.base_url = `${process.env.REACT_APP_BACKEND_URL}`;
        this.instance = msalInstance;
    }

    getUserInfo() {
        const msalAccount = this.instance.getAllAccounts();
        const account = msalAccount[0];

        const email = account.username;
        const userName = account.name;
        const fname = userName.split(' ')[0];
        const lname = userName.split(' ')[1];

        const user = {
            adminEmail: email,
            fname: fname,
            lname: lname,
        };

        return user;
    }

    /** Check for the current session validity. This function must be called before every
     * API call */
    async checkSessionValidity() {
        logUtils
            .getLogger()
            .debug(
                'CorrelationId ' + correlationId + ', Checking session validity...'
            );
        const msalAccount = this.instance.getAllAccounts();
        return await this.instance
            .acquireTokenSilent({
                ...loginRequest,
                account: msalAccount[0],
            })
            .then((response) => {
                return response.accessToken;
            });
    }

    /**
     * 
     * @param {String} methodType - request method type i.e. GET, POST, etc
     * @param {String} endpoint - backend endpoint for the request
     * @param {Object or JSON string} requestBody - the body of request
     * @param {storeDispatchFunction} storeDispatchFunction (optional) - if you want to run a redux
     *        store dispatch function on a success of the request. By default, the function sends in
     *        response.data into the action function
     * @param {errorCallback} (optional) - if you want to handle the request's error in a special way, you can 
     *        do so by passing in this errorCallback which gets called in the catch block. See its
     *        usage in the "createWebsiteReport" function in the WebsiteProblemReportService class
     * 
     * @returns {the axios response for the request}
     */
    async makeRequestToService(methodType, endpoint, requestBody, storeDispatchFunction, errorCallback) {
        const accessToken = await this.checkSessionValidity();
        const options = {
            url: `${this.base_url}${endpoint}`,
            method: methodType,
            data: requestBody && requestBody,
            headers: {
                Authorization: `Basic ${accessToken}`,
                CorrelationId: `${correlationId}`,
            },
        };

        return await axios(options)
            .then((response) => {
                if (storeDispatchFunction) {
                    store.dispatch(storeDispatchFunction(response.data))
                }

                return response;
            })
            .catch((error) => {

                if (errorCallback)
                    errorCallback(error)
                
                return error
            });
    }
})



import * as logUtils from '../utilities/LoggerUtilties';

const log = logUtils.getLogger();

export function adminRequestReducer(state = {}, action) {
    switch(action.type) {
        case "SET_ALL_ADMIN_REQUEST":
            return action.payload;
        case "CLEAR_ALL_ADMIN_REQUEST":
            return {};
        default:
            return state;
    }
}
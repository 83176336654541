import React from 'react';

import { Modal } from 'react-bootstrap';
import * as logUtils from '../utilities/LoggerUtilties';
import AdminService from '../reduxServices/AdminService';
import '../styles/pages/ConfirmAdminRequestModal.scss';
;

/** Confirmation modal for accepting admin requests
 *  @component
 */
function ConfirmAdminRequestModal(props) {
  const log = logUtils.getLogger();

  async function closeModal() {
    props.setRequestMode(false);
  }

  function handleAdminRequest(instance) {
    log.debug('Attempting admin privileges request');
    AdminService.createAdminRequest();
    closeModal();
  }

  return (
    <Modal
      id="hide-modal"
      className="hide-modal"
      show={props.requestMode}
      onHide={closeModal}
    >
      <Modal.Header closeButton>
        <h3 className="modal-title" id="modal-title">
          Are you sure you want to request admin privileges?
        </h3>
      </Modal.Header>

      <Modal.Body className="close-modal-body" id="close-modal-body">
        <p>An admin will be contacted to review your request.</p>
      </Modal.Body>

      <Modal.Footer className="close-modal-footer" id="close-modal-footer">
        <button
          id="modal-cancel-btn"
          className="modal-btn secondary-btn"
          onClick={closeModal}
        >
          Cancel
        </button>
        <button
          id="modal-request-btn"
          className="modal-btn primary-btn"
          onClick={handleAdminRequest}
        >
          Request
        </button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmAdminRequestModal;

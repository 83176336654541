import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Modal, Form, Col, Container, Typeahead, Fr } from 'react-bootstrap';
import WorkstationProblemReportService from '../reduxServices/WorkstationProblemReportService';
import * as logUtils from "../utilities/LoggerUtilties";

import { useMsal } from "@azure/msal-react";
import PodSelector from './Shared/PodAndWorkstationSelectors/PodSelector';
import CombinedReportModal from './CombinedReportModal';
import WorkstationDropDown from './WorkstationDropDown';


/**
 * Modal form for reporting workstation problems
 * Parent: HamburgerMenu, NavbarPackage
 *  @component
 */


function WorkstationProblemReportForm(props) {
    async function closeModal() {
        setSelectedWorkstationId('Workstation');
        props.close()
    }

    let userName = "";
    let firstName = "";
    let lastName = "";
    let userEmail = "";

    const log = logUtils.getLogger();

    const { accounts } = useMsal();
    if (accounts[0] != null && accounts[0] != undefined) {
        userName = accounts[0].name;
        firstName = userName.split(" ")[0]
        lastName = userName.split(" ")[1]
        userEmail = accounts[0].username;
    }



    const workstationList = useSelector(store => store.completeWorkstationListReducer);
    const [description, setDescription] = useState('');
    const [selectedWorkstationId, setSelectedWorkstationId] = useState('Workstation');

    const convertWorkstationList = (workstationObjects) => {
        const workstationIds = [];
        for (let i of workstationObjects) {
            workstationIds.push(i.workstationId)
            
        }
        return workstationIds;
    }

    const workstationIdList = convertWorkstationList(workstationList);

    const onSubmit = (e) => {
        e.preventDefault();
        if (selectedWorkstationId == 'Workstation') {
            alert("Did you forget to select a workstation?");
            return;
        }
        WorkstationProblemReportService.createWorkstationProblemReport({
            firstName,
            lastName,
            userEmail,
            description,
            workstationId: selectedWorkstationId,
            dateTime: new Date().toISOString().substring(0, 19)
        })

        closeModal()
    }

    function workstationClick (clickEvent) {
        setSelectedWorkstationId(clickEvent);
    }

    return (
        <div>
            <Container fluid >
                <Form onSubmit={onSubmit} className="admin-form">
                    <Col className="modal-container"
                    >

                        <Form.Row>
                            {/* <PodSelector data-testid="podSelector"
                                selectedWorkstationId={selectedWorkstationId}
                                setSelectedWorkstationId={setSelectedWorkstationId}
                        > */}
                            <WorkstationDropDown 
                                data-testid="podSelector"
                                workstationClick={workstationClick}
                                workstationList={workstationIdList}
                                workstationId={selectedWorkstationId}
                                isSearchable
                            />

                        </Form.Row>
                        <br />
                        <Form.Row>
                            <textarea 
                                className="wkstn-textarea" 
                                type="text" 
                                placeholder='Report Description' 
                                value={description}
                                onChange={(e) => { 
                                    setDescription(e.target.value) 
                                }} 
                                rows="10" cols="45">
                            </textarea>
                        </Form.Row>

                        <div className="buttonContainer">
                            <button
                                id="save-button"
                                className="wkstn-btn primary-btn"
                                type="submit"
                            >
                                Submit
                            </button>
                        </div>
                    </Col>
                </Form>
            </Container>
        </div>
    )
}

export default WorkstationProblemReportForm;
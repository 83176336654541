import * as logUtils from '../utilities/LoggerUtilties';

export function adminAllAvailableWorkstationReducer(state = [], action) {
  const log = logUtils.getLogger();

  switch (action.type) {
    case 'SET_ADMIN_ALL_AVAILABLE_WORKSTATION':
      log.debug('Setting allAvailableWorkstationReducer...');
      return action.payload;
    case 'CLEAR_ADMIN_ALL_AVAILABLE_WORKSTATION':
      log.debug('Clearing allAvailableWorkstationReducer...');
      return [];
    default:
      return state;
  }
}

export function adminWorkstationListReducer(state = [], action) {
  const log = logUtils.getLogger();

  switch (action.type) {
    case 'SET_ADMIN_WORKSTATION_LIST':
      log.debug('Setting workstationListReducer...');
      return action.payload;
    case 'CLEAR_ADMIN_WORKSTATION_LIST':
      log.debug('Clearing workstationListReducer...');
      return [];
    default:
      return state;
  }
}

function allPermanentReservationsAction(payload) {
  return {
    type: 'SET_ALL_PERMANENT_RESERVATIONS',
    payload: payload,
  };
}

function clearAllPermanentReservationsAction(payload) {
  return {
    type: 'CLEAR_ALL_PERMANENT_RESERVATIONS',
    payload: payload,
  };
}

function setPermanentReservationRequests(payload) {
  return {
    type: "SET_PERMANENT_RESERVATION_REQUESTS",
    payload: payload,
  }
}

function unSetPermanentReservationRequests(payload) {
  return {
    type: "UNSET_PERMANENT_RESERVATION_REQUESTS",
    payload: payload,
  }
}

export {
  allPermanentReservationsAction,
  clearAllPermanentReservationsAction,
  setPermanentReservationRequests,
  unSetPermanentReservationRequests,
};
import React from 'react';
import { PageLayout } from './components/PageLayout';
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
} from '@azure/msal-react';
import { useState } from 'react';

import './styles/pages/AllAvailableWorkstationForm.scss';
import './styles/pages/App.scss';


import SignInCard from './components/SignInCard';
import MainContent from './components/MainContent';
// import { set } from 'immer/dist/internal';

const NotLoggedIn = () => {
  return <SignInCard></SignInCard>;
};

function App() {

  const [activeMobileComponent, setActiveMobileComponent] = useState("AllAvailableWorkstationForm")

  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };

  return (
    <PageLayout setActiveMobileComponent={setActiveMobileComponent}>
      <AuthenticatedTemplate>
        <MainContent activeMobileComponent={activeMobileComponent}/>
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <NotLoggedIn />
      </UnauthenticatedTemplate>
    </PageLayout>
  );
}

export default App;
